
<LoaderWebFonts :families="fontFamilies">
  <div
    v-bind="$attrs"
    :style="theme"
    class="w-full flex-1 flex"
  >
    <slot />
  </div>
</LoaderWebFonts>
