<template>
  <LoaderWebFonts :families="fontFamilies">
    <div
      v-bind="$attrs"
      :style="theme"
      class="w-full flex-1 flex"
    >
      <slot />
    </div>
  </LoaderWebFonts>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { isNotNil, isNotNilOrEmpty } from 'ramda-adjunct'
import LoaderWebFonts from './LoaderWebFonts.vue'

const FONTS = {
  brandingFontHeading: '--cp-heading-font',
  brandingFontParagraph: '--cp-paragraph-font',
}

const getFirstNonNilOrEmptyOrDefault = objects => (prop, defaultValue) => objects
  .filter(isNotNil)
  .map(o => o[prop])
  .find(v => isNotNilOrEmpty(v)) ?? defaultValue


export default defineComponent({
  name: 'DocumentTheme',
  components: { LoaderWebFonts },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fonts = computed(() => {
      const getFontValue = getFirstNonNilOrEmptyOrDefault([
        props.document,
        props.project,
        props.organization,
      ])

      return Object.fromEntries(
        Object.keys(FONTS).map(
          fontKey => [fontKey, getFontValue(fontKey)]
        )
      )
    })

    const fontFamilies = computed(() => Object.values(fonts.value))

    const theme = computed(() => Object.fromEntries(
      Object.entries(FONTS).map(
        ([fontKey, cssVar]) => [cssVar, fonts.value[fontKey]]
      )
    ))

    return {
      fontFamilies,
      theme,
    }
  },
})
</script>
